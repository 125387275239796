/*
    hide alerts when user interacts with the close button
*/
export default function () {
    // retrieve alert element
    const alertElement = document.getElementById("alerts_container");
    // retrieve alert close button
    const closeButton = document.getElementById("alerts_close_button");
    if (closeButton) {
        closeButton.onclick = () => {
            alertElement.classList.add("content_area__alerts--dismissed");
        };
    }
}
