"use strict";
/*
    interpolate between minimum ratio & maximum ratio, based on current viewport width
*/
import TypeRatioInterpolation from "./modules/type_ratio_interpolation";
TypeRatioInterpolation();
/*
    allow enchanced focus detection
*/
import FocusAccesibility from "./modules/focus_accessibility";
FocusAccesibility();
/*
    hide alerts when user interacts with the close button
*/
import AlertVisibility from "./modules/alert_visibility";
AlertVisibility();
/*
    provide an accessible global navigation
*/
import GlobalNavigation from "./modules/global_navigation";
GlobalNavigation();
/*
    toggle display of list view item content
    on interaction with relevant button elements
*/
import ListViewItemToggle from "./modules/list_view_item_toggle";
ListViewItemToggle();
