/*
    provide an accessible global navigation
*/
export default function () {
    /* global ally */
    // get HTML element
    const htmlElement         = document.documentElement;
    // get global header element
    const globalHeaderElement = document.getElementById("global_header");
    // get navigation button element
    const navigationButton    = document.getElementById("global_header__navigation_button");
    // get navigation element
    const navigationElement   = document.getElementById("global_navigation");
    // define class to state navigation is open
    const navigationOpenClass = "navigation_is_open";
    // check for navigation element and navigation button element
    if (navigationButton && navigationElement) {
        // define disabledHandle to allow us to use a11y.js
        // to limit focus scope to a specific element
        /* eslint-disable no-unused-vars */
        let disabledHandle;
        let handle;
        /* eslint-enable no-unused-vars */
        // get elements which should be focusable when navigation is open, store them in an array
        const focusableElements = [globalHeaderElement, navigationElement];
        // on interaction with navigation button element,
        navigationButton.onclick = () => {
            // toggle `navigationOpenClass` on the HTML element
            htmlElement.classList.toggle(navigationOpenClass);
            // check whether navigation element has an attribute of "open"
            if (!navigationElement.hasAttribute("open")) {
                // if not, give it an attribute of "open",
                navigationElement.setAttribute("open", "");
                // remove the "hidden" attribute,
                navigationElement.removeAttribute("hidden");
                // and give the navigation button element an expanded ARIA attribute
                navigationButton.setAttribute("aria-expanded", "true");
                // constrain focus to the global header and global navigation
                /* eslint-disable no-unused-vars */
                disabledHandle = ally.maintain.disabled({
                    filter: focusableElements,
                });
                handle = ally.maintain.tabFocus({
                    filter: focusableElements,
                });
                /* eslint-enable no-unused-vars */
            } else {
                // if navigation element has an attribute of "open", remove the "open" attribute,
                navigationElement.removeAttribute("open");
                // give it an attribute of "hidden",
                navigationElement.setAttribute("hidden", "");
                // and remove expanded ARIA attribute from the navigation button element
                navigationButton.setAttribute("aria-expanded", "false");
                // allow focus outside of the navigation again
                disabledHandle.disengage();
                handle.disengage();
            }
        };
    }
}
